import environment from "environment";

const API = {
    VERIFY_OTP: () => {
        return `${environment.api}/endusers/verifyOTP`
    },
    LOGIN: () => {
        return `${environment.api}/endusers/requestOTP`
    }
}


export {
    API
}