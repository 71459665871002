import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './module/login/Login';
import Layout from './module/app/Layout';
import Profile from './module/app/profile/Profile';
import { lazy, Suspense } from "react";
import Loading from "./module/app/components/loading/Loding";

const Home = lazy(() => import('./module/app/home/Home'));
const Scan = lazy(() => import('./module/scan/Scan'));
const ClaimReward = lazy(() => import('./module/claim-reward/ClaimReward'));
const Redeem = lazy(() => import('./module/redeem/Redeem'));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Suspense fallback={<Loading />}><Login /></Suspense>} />
        <Route path="/scan" element={<Suspense fallback={<Loading />}><Scan /></Suspense>} />
        <Route path="/scan/:orgId/:branchId" element={<Suspense fallback={<Loading />}><Scan /></Suspense>} />
        <Route path="/redeem" element={<Suspense fallback={<Loading />}><Redeem /></Suspense>} />
        <Route path="/claim" element={<Suspense fallback={<Loading />}><ClaimReward /></Suspense>} />
        <Route path="*" element={<Layout />}>
          <Route path="home" element={<Suspense fallback={<Loading />}><Home /></Suspense>} />
          <Route path="profile" element={<Profile />} />
          <Route path="*" element={<Loading />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
