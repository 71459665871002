import { useNavigate } from 'react-router-dom';
import './profile.scss';
import MenuList from '../components/list/List';

const Profile = () => {
    const navigate = useNavigate();

    const menu = [
        {
            title: "YOUR REWARDS & BENEFITS",
            list: [
                {
                    name: 'shopping coupons',
                    icon: '/images/icons/shop.svg'
                },
                {
                    name: 'zuno coins',
                    icon: '/images/icons/coin.svg'
                }
            ]
        },
        {
            title: "MANAGEMENT",
            list: [
                {
                    name: 'Account settings',
                    icon: '/images/icons/profile.svg'
                },
                {
                    name: 'languages',
                    icon: '/images/icons/lang.svg'
                },
                {
                    name: 'Bank settings',
                    icon: '/images/icons/settings.svg'
                }
            ]
        },
        {
            title: "ABOUT",
            list: [
                {
                    name: 'terms and conditions',
                    icon: '/images/icons/T&C.svg'
                },
                {
                    name: 'sign out',
                    link: '/',
                    isLogout: true,
                    icon: '/images/icons/logout.svg'
                }
            ]
        }
    ]
    return (
        <div className="zuno-profile text-white px-3 pt-3 zuno-bg-dark">
            <div className='user-profile d-flex justify-content-center mt-3'>
                <div className='user-details text-white'>
                    <img src={'/images/image.png'} className='profile-img' />
                    <div className='mt-2'>
                        <h5>Sunder</h5>
                    </div>
                </div>
            </div>
            <MenuList menuList={menu} />
        </div>
    )
}

export default Profile;