import { useEffect, useState } from "react";

import coin from './../../icons/menus/profile.svg';
import cupon from './../../icons/menus/cupon.svg';
import settings from './../../icons/menus/settings.svg';
import home from './../../icons/menus/home.svg';

import './zuno-menu.scss';
import { useNavigate } from "react-router-dom";

const BottomMenu = ({ buttonsList }) => {

    const navigate = useNavigate();

    const [selected, setSelected] = useState('home');

    const menuList = [
        {
            icon: home,
            name: 'home',
            link: '/home',
            title: 'Home'
        },
        {
            icon: settings,
            name: 'settings',
            title: 'Settings'
        },
        {
            icon: cupon,
            name: 'cupon',
            title: 'Coupons'
        },
        {
            icon: coin,
            name: 'profile',
            link: '/profile',
            title: 'Profile'
        }
    ]


    useEffect(() => {
    }, [])

    return (
        <div className="zuno-menu d-flex">
            {
                menuList.map((e) => {
                    return (
                        <div className={"zuno-icons " + ((selected === e.name) ? 'active' : '')} onClick={(() => {
                            setSelected(e.name);
                            if (e.link) {
                                navigate(e.link);
                            }
                        })}>
                            {(selected === e.name) && <div className="zuno-menu-integator"/>}
                            <img src={e.icon} height={22} />
                            <p className="mt-1">{e.title}</p>
                        </div>
                    )
                })
            }
            
        </div>
    )
}

export default BottomMenu;