import './list.scss';

const MenuList = ({ menuList }) => {

    return (
        <div className="row menu-list">
            {
                menuList.map((e, i) => {
                    return (
                        <>
                            <p className="menu-list-title mt-5 mb-0" key={i + 'title'}>{e.title}</p>
                            {
                                e.list.map((e, i)=> {
                                    return (
                                        <div className="col-md-12 pt-2" key={i} onClick={() => {
                                            if (e.isLogout) {
                                                localStorage.clear();
                                            }
                                            if (e.link) {
                                                window.location.href = '/'
                                            }
                                        }}>
                                            <div className="d-flex justify-content-between align-item-center">
                                                <img src={e.icon} />
                                                <p className="w-100 px-3 pt-1 mb-0 pb-0">{e.name}</p>
                                                <i class="bi bi-arrow-right-short text-white align-middle" style={{ fontSize: '24px' }}></i>
                                            </div>
                                            <hr className="list-line m-0 p-0" />
                                        </div>
                                    )
                                })
                            }

                        </>
                    )
                })
            }
        </div>
    )
}

export default MenuList;