import OtpInput from "react-otp-input";

import "./login.scss";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SwipeableButton } from "react-swipeable-button";
import environment from "environment";
import axios from "axios";
import { API } from "../../service/api-config";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const swipeButton = useRef();
  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [isLoading, setLoading] = useState(false);

  const sendOTP = (number) => {
    setLoading(true);
    // axios.get(`${environment.authAPI}/${environment.apiKey}/SMS/+91${number}/AUTOGEN/OTP1`).then(e => {
    //     setSessionId(e.data.Details);
    // }).finally(() => {
    //     setLoading(false)
    // })

    axios
      .post(`${API.LOGIN()}`, {
        mobile: `+91${mobile}`,
      })
      .then((e) => {
        setSessionId(e.data.sessionId);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateOTP = (otp) => {
    setLoading(true);
    axios
      .post(API.VERIFY_OTP(), {
        mobile: `+91${mobile}`,
        otp,
        sessionId,
      })
      .then((e) => {
        localStorage.setItem("userDetails", JSON.stringify(e.data));
        navigate("/home");
      })
      .catch((e) => {
        swipeButton.current.buttonReset();
        toast.error(e.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="app-login">
      <ToastContainer />
      <div className="welcom-container">
        <div className="container px-4  pt-5">
          <h2 className="pb-5 mt-5">Tell us your Mobile Number</h2>
        </div>
      </div>
      <div className="log-line"></div>
      <div className="container">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendOTP(mobile);
          }}
        >
          <div className="input-group mt-5">
            <input
              type="number"
              value={mobile}
              onChange={({ target: { value } }) => {
                setMobile(value);
              }}
              className="form-control"
              placeholder="Mobile No"
              aria-label="Mobile No"
              aria-describedby="mobile"
              required
            />
            <button
              type="submit"
              disabled={isLoading}
              className="input-group-text"
              id="basic-addon2"
            >
              {sessionId ? "Sent" : "Send OTP"}
            </button>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label
              className="form-check-label text-light"
              for="flexCheckChecked"
            >
              allow Zuno to access your contact information from
              <a className="ms-1 text-light" href="/home">
                your contacts.
              </a>
            </label>
          </div>
        </form>
        <div className="w-100 mt-5 otp-container">
          <label className="text-white fw-bold mb-3">ENTER OTP</label>
          <OtpInput
            containerStyle={{ width: "100%" }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span className="px-2" />}
            inputType="tel"
            renderInput={(props) => (
              <input
                {...props}
                className="form-control text-center otp-input"
              />
            )}
          />
          <div className="mt-4">
            <SwipeableButton
              onSuccess={() => {
                if (otp) {
                  validateOTP(otp);
                } else {
                  swipeButton.current.buttonReset();
                }
              }}
              onFailure={() => {}}
              text="Login Now"
              text_unlocked="Loading..."
              sliderColor="#FF6E01"
              sliderTextColor="#ffffff"
              sliderIconColor="#ffffff"
              background_color="#ffffff60"
              borderRadius={30}
              ref={swipeButton}
              circle
              autoWidth
              disabled={isLoading}
              name="react-swipeable-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
